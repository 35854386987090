<template>
  <div>
    <v-chip-group column>
      <v-chip
        v-for="item in filters"
        v-if="!item.isHidden"
        :key="item.id"
        close-icon="close"
        :close="allowRemove ? !item.preventDelete : false"
        :x-color="item.data.color || null"
        @click:close="handleClose(item)"
        outlined
        small
      >
        <v-icon
          v-if="item.data.icon"
          :x-color="item.data.iconColor || null"
          small
          left
        >
          {{ item.data.icon }}
        </v-icon>
        {{ item.data.text }}
      </v-chip>
    </v-chip-group>
  </div>
</template>

<script>
// Export the SFC
export default {
  // Name of the component
  name: "AppliedFilters",

  // Accept incoming data from parent
  props: {
    // The applied filters array
    filters: {
      type: Array,
      required: true
    },

    // Option to allow removal of filters
    allowRemove: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  // Define local method functions
  methods: {
    /**
     * Emit the close event to remove the filter
     *
     * @param {Object} item
     * @returns {void}
     */
    handleClose(item) {
      this.$emit("remove", item)
    }
  }
}
</script>
